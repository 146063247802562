import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import PostsList from "../components/postsList"
import Seo from "../components/seo"
import "../styles/general.css"

export default function PostsIndex({ data, pageContext }) {
  const { edges: posts } = data.allMarkdownRemark
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <Layout>
    <Seo title="Posts" />
    <h1>Blog Posts</h1>
    <PostsList
      posts={posts}
    />
    {/*
      Pagination
    */}
    {/*
    {!isFirst && (
      <Link
        to={prevPage}
        rel="prev"
        style={{
          marginLeft: "2.5%"
        }}
      >
      Previous Page
      </Link>
    )}
    */}
    {Array.from({ length: numPages }, (_, i) => (
      <Link
        key={`pagination-number${i + 1}`}
        to={`/posts/${i === 0 ? "" : i + 1}`}
        style={{
          marginLeft: "2.5%"
        }}
      >
        <span> {i + 1} </span>
      </Link>
    ))}
    {/*
    {!isLast && (
      <Link
        to={nextPage}
        rel="next"
        style={{
          marginLeft: "2.5%"
        }}
      >
      Next Page
      </Link>
    )}
    */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostIndexQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          frontmatter {
            category
            date
            path
            tags
            title
          }
        }
      }
    }
  }
`
